import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

    const shopifyProductId = '6912379388071'
    const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

    const sizes = {
        "Breast": [
            "",
            "",
            "40.9 / 104",
            "42.5 / 108",
            "45.6 / 116",
            "",
            "",
            "49.2 / 125",
            "51.9 / 132",
        ],
        "Length": [
            "",
            "",
            "25 / 63,5",
            "25.2 / 64",
            "27.5 / 70",
            "",
            "",
            "28.7 / 73",
            "29.1 / 74",
        ]
    }


    return (
        <Layout>
            <SEO title="Shop - QR Code Sweatshirt" />

            <ProductV2 productId={base64ProductId} sizes={sizes} />
        </Layout>
    )

} export default ProductShopify